import React, {FC, useEffect, useState} from 'react';
import {Flex} from 'theme-ui';
import {css} from '@emotion/core';
import {useSelector} from 'react-redux';
import BagIcon from '../../../assets/svg/new-tab/bag.svg';
import CoffeeCupIcon from '../../../assets/svg/new-tab/coffee-cup.svg';
import GasIcon from '../../../assets/svg/new-tab/gas.svg';
import HotelIcon from '../../../assets/svg/new-tab/hotel.svg';
import PizzaIcon from '../../../assets/svg/new-tab/pizza.svg';
import DoctorIcon from '../../../assets/svg/new-tab/doctor.svg';
import Category from './Category';
import {calcBoundingBox} from './useDirectionNavigator';
import {coordinateSelector, Coords} from '../../../../../common/reducers/locationSlice';

export const CategoriesFragment: FC = () => {
    const {latitude, longitude} = useSelector(coordinateSelector);
    const [boundingBoxParam, setBoundingBoxParam] = useState<string>('');

    useEffect(() => {
        if (latitude !== null && longitude !== null) {
            const boundingBox = calcBoundingBox({latitude, longitude});
            const getPair = (coords: Coords) => `${coords.latitude},${coords.longitude}`;

            setBoundingBoxParam(
                `boundingBox=${getPair(boundingBox.lowerLeftBound)},${getPair(boundingBox.upperRightBound)}`
            );
        }
    }, [latitude, longitude]);

    return (
        <>
            <Flex
                css={css`
                    width: 100%;
                    height: 14px;

                    grid-row: 6;

                    margin: 13px 0 13px 0;
                    border-top: solid 1px rgba(0, 0, 0, 0.09);
                    padding-top: 13px;

                    font-family: Roboto;
                    font-size: 12px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    text-align: left;
                    text-transform: uppercase;
                `}
                className="main-content"
            >
                Popular Categories
            </Flex>
            <Flex
                css={css`
                    width: 100%;
                    justify-content: space-between;
                    grid-column: col-start / col-end;
                    grid-row: 7;
                `}
            >
                <Category category="hotels" icon={<HotelIcon />} label="Hotels" boundingBox={boundingBoxParam} />
                <Category category="food" icon={<PizzaIcon />} label="Food" boundingBox={boundingBoxParam} />
                <Category category="gas" icon={<GasIcon />} label="Gas Prices" boundingBox={boundingBoxParam} />
                <Category category="coffee" icon={<CoffeeCupIcon />} label="Coffee" boundingBox={boundingBoxParam} />
                <Category category="shopping" icon={<BagIcon />} label="Shopping" boundingBox={boundingBoxParam} />
                <Category category="doctors" icon={<DoctorIcon />} label="Doctors" boundingBox={boundingBoxParam} />
            </Flex>
        </>
    );
};
