import React, {FC, ReactNode, useContext, useEffect, useState} from 'react';
import {css} from '@emotion/core';
import {useStaticQuery, graphql} from 'gatsby';
import Img, {FixedObject} from 'gatsby-image';
import {useSelector} from 'react-redux';
import {NewTabContext} from '../../components/new-tab/NewTabThemeProvider';
import {useLogging} from '../../../../common/hooks/useLogging';
import {useHasMounted} from '../../../../common/hooks/useHasMounted';
import {useCanvasLoader} from '../../hooks/background-inspector';
import {RootState} from '../../rootReducer';

type AttributionUrls = {
    site: string;
    user: string;
};

type Attribution = {
    name: string;
    urls: AttributionUrls;
};

type UnsplashData = {
    imgUrl: string;
    attribution: Attribution;
    placeholderImg: FixedObject;
    altDescription: string;
} | null;

export type Ref = HTMLElement;

type BackgroundImageProps = {
    children?: ReactNode;
};

const getRandomInt = (max: number) => {
    return Math.floor(Math.random() * Math.floor(max));
};

export const getPhotoIdx = (numPhotos: number) => {
    // Returned index is an adjusted hash of the day of the year (1-366) mod number of total photos.
    // This guarantees all photos in collection are exhausted before showing first again (until year starts over).
    const now = new Date(Date.now());
    const currDayMs = Date.UTC(now.getFullYear(), now.getMonth(), now.getDate()) - Date.UTC(now.getFullYear(), 0, 0);
    const currDay = currDayMs / 24 / 60 / 60 / 1000;

    const mod = currDay % numPhotos;
    return (mod !== 0 ? mod : numPhotos) - 1;
};

const useUnsplashImage = (): UnsplashData => {
    const unsplashResponse = useStaticQuery(graphql`
        query UnsplashQuery {
            allUnsplashPhoto {
                nodes {
                    id
                    alt_description
                    placeholderImg {
                        childImageSharp {
                            fixed(width: 1920, height: 1080) {
                                ...GatsbyImageSharpFixed_withWebp
                            }
                        }
                    }
                    urls {
                        raw
                        full
                        regular
                        small
                        thumb
                    }
                    user {
                        name
                        links {
                            html
                        }
                    }
                }
            }
        }
    `);

    const newPhotoEveryTime = useSelector((state: RootState) => state.debug.newPhotoEveryTime);

    const idxSelector = newPhotoEveryTime ? getRandomInt : getPhotoIdx;
    const allPhotos = unsplashResponse.allUnsplashPhoto.nodes;
    const photoIdx = idxSelector(allPhotos.length);
    const photoOfTheDay = allPhotos[photoIdx];

    const [init, setInit] = useState(false);
    const [data, setData] = useState<UnsplashData>(null);

    useEffect(() => {
        if (!init) {
            setData({
                attribution: {
                    name: photoOfTheDay.user.name,
                    urls: {
                        site: 'https://unsplash.com/',
                        user: photoOfTheDay.user.links.html
                    }
                },
                imgUrl: photoOfTheDay.urls.thumb,
                placeholderImg: photoOfTheDay.placeholderImg.childImageSharp.fixed,
                altDescription: photoOfTheDay.alt_description
            });
            setInit(true);
        }
    }, [init, data, photoOfTheDay, newPhotoEveryTime]);

    return data;
};

const BackgroundImage: FC<BackgroundImageProps> = ({children}) => {
    const {bgColor, showPhotoGallery} = useContext(NewTabContext);
    const bgImgData = useUnsplashImage();

    const {ExtensionsErrorEvent} = useLogging();

    const bgColorStyles = css`
        width: 100%;
        height: 100%;
        padding: 0;
        margin: 0;
        position: absolute;
        overflow: hidden;
        display: flex;
        z-index: -1000;
        ${!showPhotoGallery && `background-color: ${bgColor};`};
        transition: background-color 0.4s ease;
    `;

    const imageRef = useCanvasLoader();

    const hasMounted = useHasMounted();
    if (!hasMounted || !bgImgData) {
        return null;
    }

    return (
        <>
            <Img
                ref={imageRef}
                style={{
                    position: 'absolute',
                    left: 0,
                    top: 0,
                    width: '100%',
                    height: '100%',
                    opacity: showPhotoGallery ? 1 : 0,
                    transition: 'opacity 0.4s ease',
                    zIndex: -10000,
                    // This looks silly, but we need to send a beacon to unsplash as per their api usage guide
                    // https://unsplash.com/documentation#hotlinking
                    backgroundImage: `url(${bgImgData.imgUrl})`
                }}
                fadeIn
                onError={(err) => {
                    ExtensionsErrorEvent({type: 'backgroundImage', message: err.message});
                }}
                loading="eager"
                fixed={bgImgData.placeholderImg}
                alt={bgImgData.altDescription}
            />

            {bgImgData.attribution && showPhotoGallery && (
                <figcaption>
                    <UnsplashAttribution name={bgImgData.attribution.name} urls={bgImgData.attribution.urls} />
                </figcaption>
            )}
            <div css={[bgColorStyles]} />
            {children}
        </>
    );
};

const UnsplashAttribution: FC<Attribution> = ({name, urls}) => {
    return (
        <div
            css={css`
                font-size: 0.8em;
                position: absolute;
                bottom: 1.5rem;
                right: 1rem;
                color: white;
                opacity: 0.75;
                z-index: 1;
                a {
                    color: white;
                    font-weight: normal;
                    text-decoration: none;
                }
            `}
        >
            Photo by{' '}
            <a href={urls.user} target="_blank" rel="noreferrer">
                {name}
            </a>{' '}
            on{' '}
            <a href={urls.site} target="_blank" rel="noreferrer">
                Unsplash
            </a>
        </div>
    );
};

export default BackgroundImage;
