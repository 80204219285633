import React, {FC, useCallback, useContext, useEffect, useState} from 'react';
import {css} from '@emotion/core';
import {Flex} from 'theme-ui';
import {buildSearchURL} from '@s1/extensions';
import {useSelector} from 'react-redux';
import {useLocation} from '@reach/router';
import NewTabThemeProvider, {NewTabContext} from '../components/new-tab/NewTabThemeProvider';
import {Main} from '../../../common/templates/RootLayoutComponents';
import BackgroundImage from '../components/new-tab/BackgroundImage';
import BottomMenu from '../components/new-tab/BottomMenu';
import SpeedDial from '../components/new-tab/SpeedDial';
import PrivateSearchBar from '../components/new-tab/PrivateSearchBar';
import Sidebar from '../components/new-tab/SideBar/SideBar';
import Redirect from '../../mapquest/util/redirect';
import {useHistory} from '../hooks/useHistory';
import {useClickOutside} from '../../../common/hooks/useClickOutside';
import SEO from '../../../common/components/SEO';
import {fetchRemoteConfig} from '../../../common/reducers/remoteConfig';
import {useAppDispatch} from '../../../common/stores';
// @ts-expect-error
import MapQuestNewtabHelper from '../assets/videos/MapQuestNewtabHelper.mp4';
// @ts-expect-error
import MapQuestNewtabPin from '../assets/videos/MapQuestNewtabPin.mp4';

import {
    walmartIcon,
    facebookIcon,
    ebayIcon,
    startpageIcon,
    weatherChannelIcon,
    youTubeIcon
} from '../assets/images/SpeedDial';
import {useLogging} from '../../../common/hooks/useLogging';
import UpdateModal from '../components/new-tab/updateModal';
import {MapQuestLogo} from '../components/MapQuestLogo';
import {RootState} from '../rootReducer';
import {getExtensionName} from '../../../common/ extensionMap';
import KeepChanges from '../components/new-tab/KeepChanges';
import VideoPopUp from '../components/new-tab/VideoPopUp';
import {
    isFirstLoadSelector,
    isFirstWindowClickSelector,
    isSecondWindowClickSelector
} from '../../../common/selectors/index';
import incrementSlice from '../../../common/reducers/appSlice';

const NewTab: FC<{}> = () => {
    const {ExtensionsErrorEvent, InfoEvent} = useLogging();
    const dispatch = useAppDispatch();

    const remoteConfig = useSelector((state: RootState) => state.remoteConfig);
    const location = useLocation();

    const isFirstLoad = useSelector(isFirstLoadSelector);
    const isFirstWindowClick = useSelector(isFirstWindowClickSelector);
    const isSecondWindowClick = useSelector(isSecondWindowClickSelector);

    useEffect(() => {
        dispatch(incrementSlice.actions.incrementPageLoad(1));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!remoteConfig.valid) {
            InfoEvent({type: 'dispatch', message: 'RemoteConfig'});
            dispatch(fetchRemoteConfig());
        } else if (remoteConfig.errMsg !== '') {
            ExtensionsErrorEvent({type: 'RemoteConfig', message: remoteConfig.errMsg});
        }
    }, [dispatch, remoteConfig.valid, remoteConfig.errMsg, ExtensionsErrorEvent, InfoEvent]);

    const onSubmit = ({query}: {query: string}) => {
        const url = buildSearchURL({
            baseUrl: 'https://ext.mapquest.com',
            searchPath: 'serp',
            searchParam: 'q',
            query,
            segment: 'mq.extension.yhs01',
            marketingParams: {...remoteConfig, extName: getExtensionName(location.pathname)},
            ignoreRedirect: true
        });
        Redirect(url.toString());
    };

    const [drawerClosed, setDrawerClosed] = useState<boolean>(!isFirstLoad);

    useEffect(() => {
        const handleClick = () => {
            dispatch(incrementSlice.actions.incrementWindowClick(1));
        };

        if (isFirstLoad) {
            window.addEventListener('click', handleClick);
        }
    }, [dispatch, isFirstLoad]);

    const onClickOutside = useCallback(() => {
        if (!document.getElementById('keep-changes')) {
            setDrawerClosed(true);
        }
        if (document.getElementById('keep-changes')) {
            setDrawerClosed(false);
        }
    }, []);

    const clickOutsideRef = useClickOutside(onClickOutside);

    const toggleCollapsed = useCallback(() => {
        setDrawerClosed(!drawerClosed);
    }, [drawerClosed]);

    return (
        <Main>
            <UpdateModal />
            <KeepChanges />
            {isFirstLoad && isFirstWindowClick ? (
                <VideoPopUp
                    video={MapQuestNewtabHelper}
                    styles={`
                        height: 300px;
                        position: absolute;
                        z-index: 10000;
                        right: 15px;
                        bottom: 0px;
                    `}
                />
            ) : (
                <></>
            )}
            {isFirstLoad && isSecondWindowClick ? (
                <VideoPopUp
                    video={MapQuestNewtabPin}
                    styles={`
                        height: 300px;
                        position: absolute;
                        z-index: 10000;
                        right: 15px;
                        top: 60px;
                    `}
                />
            ) : (
                <></>
            )}
            <BackgroundImage>
                <div
                    css={css`
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        top: 0;
                        left: 0;
                        transition: background-color 0.3s ease;
                        ${drawerClosed
                            ? 'z-index: 0;'
                            : `
                                background-color: rgba(0, 0, 0, 0.6);
                                z-index: 1;
                                `}
                    `}
                />
                <Flex
                    css={css`
                        width: 100%;
                        align-items: center;
                        justify-content: center;
                        justify-content: space-between;
                        max-width: 1440px;
                    `}
                >
                    <MapQuestLogo />
                    <Sidebar />
                </Flex>
                <Flex
                    css={css`
                        width: 100%;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        position: relative;
                        margin: auto;
                    `}
                >
                    <Flex
                        css={css`
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            background-color: rgba(0, 0, 0, 0);
                            z-index: 10;
                            pointer-events: none;
                        `}
                    />
                    <Flex
                        css={css`
                            flex-direction: column;
                            width: 100%;
                            max-width: 677px;
                        `}
                    >
                        <PrivateSearchBar placeholder="Search" onSubmit={onSubmit} />
                        <SpeedDials />
                        <BottomMenu
                            ref={clickOutsideRef}
                            title="MapQuest Directions"
                            collapsed={drawerClosed}
                            onClickToggle={toggleCollapsed}
                        />
                    </Flex>
                </Flex>
            </BackgroundImage>
        </Main>
    );
};

const SpeedDials = () => {
    const {showMostVisited, showPopular} = useContext(NewTabContext);
    const history = useHistory();

    return (
        <div
            css={css`
                min-height: 454px;
                margin-top: 54px;
                display: grid;
                grid-template-columns: 1fr;
                grid-auto-rows: min-content;
                gap: 27px;
            `}
        >
            {showMostVisited && <SpeedDial title="Most Visited" items={history} />}
            {showPopular && (
                <SpeedDial
                    title="Popular"
                    items={[
                        {
                            url: 'https://youtube.com',
                            label: 'YouTube',
                            iconUrl: youTubeIcon
                        },
                        {
                            url: 'https://www.walmart.com',
                            label: 'Walmart',
                            iconUrl: walmartIcon
                        },
                        {
                            url: 'https://weather.com',
                            label: 'Weather',
                            iconUrl: weatherChannelIcon
                        },
                        {
                            url: 'https://www.facebook.com',
                            label: 'Facebook',
                            iconUrl: facebookIcon
                        },
                        {
                            url: 'https://www.startpage.com',
                            label: 'Startpage',
                            iconUrl: startpageIcon
                        },
                        {
                            url: 'http://ebay.us/KVGX8q',
                            label: 'eBay',
                            iconUrl: ebayIcon
                        }
                    ]}
                />
            )}
        </div>
    );
};

const Root: FC = () => (
    <NewTabThemeProvider>
        <SEO title="New Tab" />
        <NewTab />
    </NewTabThemeProvider>
);
export default Root;
