import React, {useContext} from 'react';
import {css} from '@emotion/core';
import {NewTabContext} from '../../../components/new-tab/NewTabThemeProvider';

const ColorSelect = () => {
    const {colorTheme, colorThemes, showPhotoGallery, toggleShowPhotoGallery, setColorTheme} = useContext(
        NewTabContext
    );

    const colorSelectButtonCss = css`
        height: 54px;
        width: 54px;
        border: none;
        border-radius: 54px;
        cursor: pointer;

        &:focus {
            outline: none;
        }

        &.active {
            border: 1px solid #1ab17c;
            position: relative;

            &:after {
                content: '✔';
                position: absolute;
                right: 0;
                top: 1px;
                font-size: 12px;
                color: #ffffff;
                width: 13px;
                height: 13px;
                text-align: center;
                border: 1px solid #ffffff;
                background: #1ab17c;
                border-radius: 50%;
            }
        }
    `;

    const colorSelectContainerCss = css`
        width: 100%;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        column-gap: 20px;
        row-gap: 20px;
    `;

    return (
        <div css={colorSelectContainerCss}>
            {colorThemes.map((theme) => {
                return (
                    <button
                        key={theme.name}
                        className={theme.name === colorTheme.name && !showPhotoGallery ? 'active' : ''}
                        type="button"
                        aria-label={theme.name}
                        onClick={() => {
                            // Disable photo gallery if color is selected.
                            if (showPhotoGallery) {
                                toggleShowPhotoGallery();
                            }
                            setColorTheme(theme);
                        }}
                        css={[
                            css`
                                background-color: ${theme.bgColor};
                            `,
                            colorSelectButtonCss
                        ]}
                    />
                );
            })}
        </div>
    );
};

export default ColorSelect;
