import {Dispatch, SetStateAction, useEffect, useState} from 'react';

export const useStickyState = <T extends unknown>(
    key: string,
    deserialize: (v: unknown) => T
): [T, Dispatch<SetStateAction<T>>] => {
    const [value, setValue] = useState(() => {
        const stickyValueStr = typeof window !== 'undefined' ? window.localStorage.getItem(key) : null;

        let stickyValue: unknown = null;
        if (stickyValueStr !== null) {
            try {
                stickyValue = JSON.parse(stickyValueStr);
            } catch (err) {
                // Invalid JSON. Remove entry from local storage.
                window.localStorage.removeItem(key);
            }
        }

        return deserialize(stickyValue);
    });

    useEffect(() => {
        if (typeof window !== 'undefined') {
            window.localStorage.setItem(key, JSON.stringify(value));
        }
    }, [key, value]);

    return [value, setValue];
};
