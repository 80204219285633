import {css} from '@emotion/core';
import React, {FC, useContext} from 'react';
import {Flex, IconButton, Text} from 'theme-ui';
import DirectionsContext from './ContextProvider';
import Redirect from '../../../util/redirect';

const categoryMap = {
    hotels: 'Hotels',
    food: 'Restaurants',
    gas: 'Gas',
    shopping: 'Shopping',
    coffee: 'Coffee',
    doctors: 'Medical'
};

type CategoryName = keyof typeof categoryMap;

const redirectToMapQuest = (category: CategoryName, box = '') => {
    if (category === 'hotels') {
        Redirect('https://bookings.mapquest.com/hotels');
        return;
    }

    let qs = `query=${categoryMap[category]}`;
    if (box !== '') {
        qs += `&${box}`;
    }

    const url = `https://www.mapquest.com/search/results?${qs}`;
    Redirect(url);
};

type DirectionsCategoryProps = {
    icon: React.ReactElement;
    boundingBox: string;
    label: string;
    category: CategoryName;
};

const Category: FC<DirectionsCategoryProps> = ({icon, label, category, boundingBox}) => {
    const {handleCategoryChange} = useContext<DirectionsContext>(DirectionsContext);

    return (
        <Flex
            css={css`
                flex-direction: column;
                width: 100%;
                max-width: 70px;
                align-items: center;
                font-size: 12px;
                cursor: pointer;
                padding: 12px 0 8px;
                border-radius: 6px;

                &:hover {
                    box-shadow: 0 2px 11px 0 rgba(19, 43, 69, 0.14);
                    background-color: #ffffff;
                }
            `}
            onClick={() => {
                redirectToMapQuest(category, boundingBox);
            }}
        >
            <IconButton
                type="button"
                css={css`
                    outline: none;
                    width: 42px;
                    height: 42px;
                    margin-bottom: 0.5rem;
                    cursor: pointer;
                `}
                onClick={() => {
                    handleCategoryChange(category);
                }}
            >
                {icon}
            </IconButton>
            <Text>{label}</Text>
        </Flex>
    );
};

export default Category;
