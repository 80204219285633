import {createStyles, Theme, withStyles} from '@material-ui/core';
import Switch, {SwitchClassKey, SwitchProps} from '@material-ui/core/Switch';
import React from 'react';

interface Styles extends Partial<Record<SwitchClassKey, string>> {
    focusVisible?: string;
}

interface Props extends SwitchProps {
    classes: Styles;
}

const switchStyles = (theme: Theme) =>
    createStyles({
        root: {
            width: 42,
            height: 26,
            padding: 0,
            margin: theme.spacing(1)
        },
        switchBase: {
            padding: 1,
            '&$checked': {
                transform: 'translateX(16px)',
                color: '#1ab17c',
                '& + $track': {
                    backgroundColor: '#ebf0f4',
                    opacity: 1,
                    border: 'none'
                }
            },
            '&$focusVisible $thumb': {
                color: '#52d869',
                border: '6px solid #fff'
            }
        },
        thumb: {
            width: '22px',
            height: '22px',
            marginTop: '1px'
        },
        track: {
            borderRadius: 26 / 2,
            backgroundColor: '#ebf0f4',
            opacity: 1,
            transition: theme.transitions.create(['background-color', 'border'])
        },
        checked: {},
        focusVisible: {}
    });

const Toggle = withStyles(switchStyles)(({classes, ...props}: Props) => {
    return (
        <Switch
            focusVisibleClassName={classes.focusVisible}
            disableRipple
            classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked
            }}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
        />
    );
});

export default Toggle;
