/* eslint-disable @typescript-eslint/no-unused-vars */
import {Dimension2D, PixelData} from './types';

export const selectVisibleRegion = (
    {width: newWidth, height: newHeight}: Dimension2D,
    {width: srcWidth, height: srcHeight}: Dimension2D
) => {
    const srcAspectRatio = srcWidth / srcHeight;
    const newAspectRatio = newWidth / newHeight;

    let renderHeight = newHeight;
    let renderWidth = newWidth;

    if (srcAspectRatio > newAspectRatio) {
        renderHeight = newHeight;
        renderWidth = renderHeight * srcAspectRatio;
    }

    if (srcAspectRatio < newAspectRatio) {
        renderWidth = newWidth;
        renderHeight = renderWidth / srcAspectRatio;
    }

    const left = -(renderWidth - newWidth) / 2;
    const top = -(renderHeight - newHeight) / 2;

    return {
        width: renderWidth,
        height: renderHeight,
        left,
        top
    };
};

export const asRGBArray = ({data}: ImageData) => {
    const rgbArray: PixelData[] = [];
    for (let i = 0; i < data.length; i += 4) {
        const r = i;
        const g = i + 1;
        const b = i + 2;
        const a = i + 3;
        rgbArray.push({r: data[r], g: data[g], b: data[b], a: data[a]});
    }
    return rgbArray;
};

const toGrayScale = (imageData: ImageData): ImageData => {
    const {data} = imageData;
    const grayData = new ImageData(imageData.width, imageData.height);

    for (let i = 0; i < data.length; i += 4) {
        const avg = (data[i] + data[i + 1] + data[i + 2]) / 3;
        data[i] = avg; // red
        data[i + 1] = avg; // green
        data[i + 2] = avg; // blue
    }

    grayData.data.set(data);

    return grayData;
};

const toGrayScalePixel = (px: PixelData, useAlpha = false): PixelData => {
    let sum = px.r + px.g + px.b;
    let channels = 3;
    if (useAlpha) {
        sum += px.a;
        channels += 1;
    }
    const avg = sum / channels;
    return {r: avg, g: avg, b: avg, a: useAlpha ? avg : 255};
};

export const calcAveragePixel = (imageData: ImageData): PixelData => {
    const {data} = imageData;
    let R = 0;
    let G = 0;
    let B = 0;
    let A = 0;

    const numChannels = 4;
    const pixelsPerChannel = data.length / numChannels;

    for (let i = 0; i < data.length; i += numChannels) {
        const r = data[i];
        const g = data[i + 1];
        const b = data[i + 2];
        const a = data[i + 3];

        // taking the sqrt of the sum of squares for averaging
        // to keep more vibrancy in the avg pixel
        R += r * r;
        G += g * g;
        B += b * b;
        A += a;
    }

    const doAvg = (val: number, base: number) => {
        return Math.sqrt(val / base);
    };

    R = doAvg(R, pixelsPerChannel);
    G = doAvg(G, pixelsPerChannel);
    B = doAvg(B, pixelsPerChannel);

    return {r: R, g: G, b: B, a: A / pixelsPerChannel};
};

const selectExtremeValue = (colorValue: number, bp = 0, bpScale = 1) => {
    const bandFilterSize = 20;

    let min = Math.max(0, bp * bpScale);
    let max = Math.min(255 - bp * bpScale, 255);

    // the avg pixels right below the 'midpoint (128)' are still
    // extremely light visually, so choosing the 'opposite'
    // results in an artificually light pixel, so flip it again
    if (128 - bandFilterSize <= colorValue && colorValue <= 128 + bandFilterSize) {
        const tmp = min;
        min = max;
        max = tmp;
    }

    return colorValue < 128 ? max : min;
};

const snapToExtreme = (px: PixelData): PixelData => {
    return {
        r: selectExtremeValue(px.r),
        g: selectExtremeValue(px.g),
        b: selectExtremeValue(px.b),
        a: 255
    };
};

const selectiveInvert = (px: PixelData, minChannelDelta: number) => {
    const max = Math.max(px.r, px.g, px.b);
    const min = Math.min(px.r, px.g, px.b);
    const channelMean = (px.r + px.g + px.b) / 3;
    const channelDelta = Math.abs(min - max);

    if (channelDelta < minChannelDelta || channelMean > 100) {
        return {
            r: selectExtremeValue(channelMean + channelDelta ** 2),
            g: selectExtremeValue(channelMean + channelDelta ** 2),
            b: selectExtremeValue(channelMean + channelDelta ** 2),
            a: px.a
        };
    }

    if (channelMean > 180 && channelDelta > 30) {
        return {
            r: Math.abs(px.r - 128),
            g: Math.abs(px.g - 128),
            b: Math.abs(px.b - 128),
            a: px.a
        };
    }

    if (channelMean < 110) {
        return {
            r: 255,
            g: 255,
            b: 255,
            a: 255
        };
    }

    return px;
};

const monotoneStrategy = (imageData: ImageData) => {
    const avgPixel = calcAveragePixel(imageData);
    const grayPixel = toGrayScalePixel(avgPixel);
    const monotone = snapToExtreme(grayPixel);
    return monotone;
};

export type StrategyName = 'average' | 'monotone';
type ColorStrategy = (imageData: ImageData) => PixelData;

const strategies: Record<StrategyName, ColorStrategy> = {
    average: calcAveragePixel,
    monotone: monotoneStrategy
};

export const getColor = (imageData: ImageData, key: StrategyName = 'monotone'): PixelData => {
    return strategies[key](imageData);
};
