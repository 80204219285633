import {useCallback, useState} from 'react';
import {Coords} from '../../../../../common/reducers/locationSlice';

import Redirect from '../../../util/redirect';
import {LocationName} from './ContextProvider';

type NonNullRecord<T> = {
    [P in keyof T]: NonNullable<T[P]>;
};

type DirectionUrlState = {
    [key in LocationName]: string;
};

type UrlParamState = {
    [key: string]: string | null;
};

interface DirectionUrlHook {
    navigate(): void;
    updateDirectionUrl(inputState?: {[key: string]: string}, inputParams?: UrlParamState): void;
    reverseDirectionUrl(): void;
    getDirectionUrl(): string;
}

export type PublicDirectionUrl = Pick<DirectionUrlHook, 'navigate' | 'updateDirectionUrl' | 'getDirectionUrl'>;

const BASE_URL = 'https://www.mapquest.com/';

const buildDirectionsUrl = ({from, to}: DirectionUrlState, params?: UrlParamState): URL => {
    const url = new URL(`directions/from${from}/to${to}`, BASE_URL);

    if (params) {
        Object.entries(params).forEach(([key, value]) => {
            if (value) {
                url.searchParams.set(key, value);
            }
        });
    }
    return url;
};

export type BoundingBox = {
    lowerLeftBound: NonNullRecord<Coords>;
    upperRightBound: NonNullRecord<Coords>;
};

export const calcBoundingBox = (
    position: NonNullRecord<Coords>,
    variance: NonNullRecord<Coords> = {latitude: 0.01, longitude: 0.015}
): BoundingBox => {
    return {
        lowerLeftBound: {
            latitude: position.latitude - variance.latitude,
            longitude: position.longitude - variance.longitude
        },
        upperRightBound: {
            latitude: position.latitude + variance.latitude,
            longitude: position.longitude + variance.latitude
        }
    };
};

export const useDirectionNavigator = (): DirectionUrlHook => {
    const [directionUrlState, setDirectionUrlState] = useState<DirectionUrlState>({
        to: '',
        from: ''
    });

    const [urlParams, setUrlParams] = useState<UrlParamState>({});

    const updateDirectionUrl = (inputState?: {[key: string]: string}, inputParams?: UrlParamState) => {
        if (inputState) {
            setDirectionUrlState({...directionUrlState, ...inputState});
        }

        if (inputParams) {
            setUrlParams({...urlParams, ...inputParams});
        }
    };

    const reverseDirectionUrl = () => {
        const {from, to} = directionUrlState;
        updateDirectionUrl({from: to, to: from});
    };

    const navigate = useCallback(() => {
        if (directionUrlState.to && directionUrlState.from) {
            Redirect(buildDirectionsUrl(directionUrlState, urlParams).toString());
        }
    }, [directionUrlState, urlParams]);

    const getDirectionUrl = () => {
        if (directionUrlState.to && directionUrlState.from) {
            return buildDirectionsUrl(directionUrlState, urlParams).toString();
        }
        return BASE_URL;
    };

    return {navigate, updateDirectionUrl, reverseDirectionUrl, getDirectionUrl};
};
