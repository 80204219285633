const isServerside = () => {
    return typeof window === 'undefined';
};

const getCanvas = () => {
    if (isServerside()) {
        return {
            width: 0,
            height: 0,
            // clientside, 'canvas.getContext' is a factory function
            // but we only care about 2D so satisfy the interface
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            getContext: (ctx: string) => {
                return new CanvasRenderingContext2D();
            }
        };
    }

    const cvs = document.createElement('canvas');
    cvs.style.position = 'absolute';
    cvs.id = 'debug-overlay';
    cvs.style.top = '0';
    cvs.style.left = '0';
    cvs.style.zIndex = '999';
    cvs.width = window.innerWidth;
    cvs.height = window.innerHeight;
    return cvs;
};

const canvas = getCanvas();

export const getContext = (): CanvasRenderingContext2D => {
    // getContext can only return null if it's passed with an
    // invalid string.  In this case, '2d' is valid
    return canvas.getContext('2d') as CanvasRenderingContext2D;
};

export default canvas;
