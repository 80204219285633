import React, {FC, useCallback, useContext} from 'react';
import {css} from '@emotion/core';
import {Text, Button, Flex} from 'theme-ui';
import ReverseIcon from '../../../assets/svg/new-tab/reverse.svg';
import LocationInput from './DirectionsInput';
import {LocationSuggest} from './Suggestions';
import DirectionsContext from './ContextProvider';
import TravelModeMenu from './TravelModes';

export const GoToDirectionsButton: FC = () => {
    const {navigate, locationsSatisfied} = useContext(DirectionsContext);

    const allLocationsSatisfied = useCallback(() => {
        return locationsSatisfied.from && locationsSatisfied.to;
    }, [locationsSatisfied.from, locationsSatisfied.to]);

    return (
        <Button
            css={css`
                position: absolute;
                grid-row: 5;
                z-index: 1;
                top: 0;
                right: 0;
                width: 100%;
                max-width: 78px;
                margin: 0 0 0 auto;
                color: #fff;
                background-color: '#007aff';
                border-radius: 15px;
                padding: unset;

                height: 26px;
                font-size: 12px;
                outline: none;
                cursor: pointer;
                margin-bottom: 26px;

                &:disabled {
                    background-color: #acb3bc;
                    cursor: unset;
                }
            `}
            disabled={!allLocationsSatisfied()}
            onClick={() => navigate()}
            className="suggest-container"
        >
            <Text>GO</Text>
        </Button>
    );
};

const DirectionsFragment: FC = () => {
    const {handleReverse} = useContext(DirectionsContext);

    return (
        <>
            <TravelModeMenu />
            <LocationInput name="from" />
            <LocationInput name="to" />
            <Flex
                css={css`
                    width: 12px;
                    justify-content: center;
                    margin: auto;
                `}
                className="reverse"
            >
                <ReverseIcon onClick={handleReverse} />
            </Flex>
            <LocationSuggest />
            <GoToDirectionsButton />
        </>
    );
};

export default DirectionsFragment;
