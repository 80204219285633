import React, {FC, ReactElement, useContext, useState} from 'react';
import {css} from '@emotion/core';
import RoundIcon from '../RoundIcon';
import WalkingIcon from '../../../assets/svg/new-tab/walking.svg';
import MapquestIcon from '../../../assets/svg/mapquest-icon-white.svg';
import {DirectionsContext} from './ContextProvider';

type TravelModeProps = {
    // eslint-disable-next-line
    name: string;
    active?: boolean;
    icon: ReactElement;
    onClick: () => void;
};

const TravelMode: FC<TravelModeProps> = ({active, icon, onClick}) => {
    const color = active ? '#007aff' : `#fff`;
    const style = active
        ? css`
              svg path {
                  fill: #fff;
              }
          `
        : css`
              svg path {
                  fill: #222;
              }
              box-shadow: 0 2px 11px 0 rgba(27, 80, 137, 0.13);
          `;

    return (
        <RoundIcon
            color={color}
            icon={icon}
            onClick={onClick}
            style={css`
                cursor: pointer;
                ${style}
            `}
        />
    );
};

const fullRowCss = css`
    display: flex;
    justify-content: start;
    align-items: center;
`;

const TravelModeMenu: FC = () => {
    const {updateDirectionUrl} = useContext(DirectionsContext);
    const [activeIdx, setActiveIdx] = useState<number>(0);

    const modes: TravelModeProps[] = [
        {
            name: 'driving',
            icon: <MapquestIcon height={14} />,
            onClick: () => updateDirectionUrl(undefined, {routeType: null})
        },
        {
            name: 'walking',
            icon: <WalkingIcon />,
            onClick: () => updateDirectionUrl(undefined, {routeType: 'pedestrian'})
        }
    ];

    const getActiveModeTitle = () => {
        return `${modes[activeIdx]?.name} directions`;
    };

    const ActiveModeTitle: FC = () => {
        return (
            <div
                css={css`
                    display: block;
                    width: 100%;
                    height: 14px;
                    font-family: Roboto;
                    font-size: 12px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    text-align: center;
                    text-transform: uppercase;

                    ${fullRowCss}
                `}
                className="main-content"
            >
                {getActiveModeTitle()}
            </div>
        );
    };

    return (
        <>
            <ActiveModeTitle />
            <div css={fullRowCss} className="main-content">
                {modes.map(({name, icon, onClick}, idx) => (
                    <TravelMode
                        key={name}
                        name={name}
                        icon={icon}
                        active={activeIdx === idx}
                        onClick={() => {
                            setActiveIdx(idx);
                            onClick();
                        }}
                    />
                ))}
            </div>
        </>
    );
};

export default TravelModeMenu;
