import {useEffect, useState} from 'react';

export const useHasMounted = () => {
    const [hasMounted, setHasMounted] = useState(false);

    // The empty dependency array [] guarantees that this will only trigger a re-render once after hydration.
    useEffect(() => {
        setHasMounted(true);
    }, []);

    return hasMounted;
};
