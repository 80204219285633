import React, {FC, PropsWithChildren, useState} from 'react';
import {PublicDirectionUrl, useDirectionNavigator} from './useDirectionNavigator';

type DirectionQueryState = {
    category: string;
    from: string;
    to: string;
};

export type LocationName = keyof Pick<DirectionQueryState, 'from' | 'to'>;

type DirectionStateApi = {
    handleReverse(): void;
    handleInputChange(inputState: {[key: string]: string}): void;
    handleCategoryChange(category: string): void;
};

type ActiveSearchbarState = {
    activeSearchBar: LocationName;
    setActiveSearchBar: React.Dispatch<LocationName>;
};

type SuggestionVisibilityState = {
    showSuggestions: boolean;
    setShowSuggestions: React.Dispatch<boolean>;
};

type SatisfiedLocations = {
    to: boolean;
    from: boolean;
};

type LocationsSatisfiedState = {
    locationsSatisfied: SatisfiedLocations;
    setLocationSatisfiedState: (locationState: {[locationName: string]: boolean}) => void;
};

type DirectionsContext = DirectionQueryState &
    DirectionStateApi &
    ActiveSearchbarState &
    SuggestionVisibilityState &
    PublicDirectionUrl &
    LocationsSatisfiedState;

const ctxPlaceholderFunc = () => {
    // do nothing.
};

export const DirectionsContext = React.createContext<DirectionsContext>({
    category: '',
    from: '',
    to: '',

    handleReverse: ctxPlaceholderFunc,
    handleInputChange: ctxPlaceholderFunc,
    handleCategoryChange: ctxPlaceholderFunc,

    activeSearchBar: 'from',
    setActiveSearchBar: ctxPlaceholderFunc,

    showSuggestions: false,
    setShowSuggestions: ctxPlaceholderFunc,

    locationsSatisfied: {to: false, from: false},
    setLocationSatisfiedState: ctxPlaceholderFunc,

    navigate: ctxPlaceholderFunc,
    updateDirectionUrl: ctxPlaceholderFunc,
    getDirectionUrl: () => ''
});

export const DirectionsContextProvider: FC<PropsWithChildren<unknown>> = ({children}) => {
    const [directionState, setDirectionState] = useState<DirectionQueryState>({
        category: '',
        from: '',
        to: ''
    });

    const {navigate, updateDirectionUrl, reverseDirectionUrl, getDirectionUrl} = useDirectionNavigator();

    const [activeSearchBar, setActiveSearchBar] = useState<LocationName>('from');

    const [showSuggestions, setShowSuggestions] = useState(false);

    const [locationsSatisfied, setLocationsSatisfied] = useState({to: false, from: false});

    const handleReverse = () => {
        const {from, to} = directionState;
        setDirectionState({...directionState, ...{from: to, to: from}});
        reverseDirectionUrl();
    };

    const handleInputChange = (inputState: {[key: string]: string}) => {
        setDirectionState({...directionState, ...inputState});
        setShowSuggestions(true);
    };

    const handleCategoryChange = (category: string) => {
        setDirectionState({...directionState, category});
    };

    const setLocationSatisfiedState = (locationSatisfiedState: {[locationName: string]: boolean}) => {
        setLocationsSatisfied({...locationsSatisfied, ...locationSatisfiedState});
    };

    const directionsApi = {
        ...directionState,
        activeSearchBar,
        showSuggestions,
        handleReverse,
        handleInputChange,
        handleCategoryChange,
        setActiveSearchBar,
        setShowSuggestions,
        locationsSatisfied,
        setLocationSatisfiedState,
        navigate,
        updateDirectionUrl,
        getDirectionUrl
    };

    return <DirectionsContext.Provider value={directionsApi}>{children}</DirectionsContext.Provider>;
};

export default DirectionsContext;
