import {useCallback, useEffect, useContext, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '../../rootReducer';
import {selectVisibleRegion} from '../../../../common/imaging';
import canvas, {getContext} from '../../../../common/imaging/canvas';
import canvasSlice from './canvasSlice';
import {NewTabContext} from '../../components/new-tab/NewTabThemeProvider';
import {useWindowDims} from './useWindowDims';

const useCanvasLoader = () => {
    const dispatch = useDispatch();
    const [imgElem, setImgElem] = useState<HTMLImageElement>(new Image());
    const {showCanvasOverlay} = useSelector((state: RootState) => state.debug);
    const dims = useWindowDims();

    const {
        showPhotoGallery,
        colorTheme: {bgColor}
    } = useContext(NewTabContext);

    const drawBackgroundColor = useCallback(() => {
        const region = selectVisibleRegion(canvas, canvas);
        const {left, top, width, height} = region;

        const ctx = getContext();
        ctx.fillStyle = bgColor;
        ctx.fillRect(left, top, width, height);
        dispatch(canvasSlice.actions.setCanvasRender(bgColor));
    }, [bgColor, dispatch]);

    const drawImage = useCallback(() => {
        const region = selectVisibleRegion(canvas, {
            width: imgElem.naturalWidth,
            height: imgElem.naturalHeight
        });
        const {left, top, width, height} = region;

        const ctx = getContext();
        ctx.drawImage(imgElem, left, top, width, height);
        dispatch(canvasSlice.actions.setCanvasRender('image'));
    }, [dispatch, imgElem]);

    const shouldRenderImage = useCallback(() => {
        return showPhotoGallery && imgElem.src !== '';
    }, [imgElem.src, showPhotoGallery]);

    const drawCanvas = useCallback(() => {
        if (shouldRenderImage()) {
            drawImage();
        } else {
            drawBackgroundColor();
        }
    }, [drawBackgroundColor, drawImage, shouldRenderImage]);

    useEffect(() => {
        const resizeCanvas = () => {
            canvas.width = window.innerWidth;
            canvas.height = window.innerHeight;
        };

        if (imgElem) {
            resizeCanvas();
            drawCanvas();
        }
    }, [dims, drawCanvas, imgElem]);

    useEffect(() => {
        if (typeof window !== 'undefined') {
            // eslint-disable-next-line no-unused-expressions
            showCanvasOverlay ? document.body.appendChild(canvas as HTMLElement) : (canvas as HTMLElement).remove();
        }
    }, [showCanvasOverlay]);

    // GatsbyImage doesn't allow ref forwarding
    // so we're using a callback to get the intial ref
    const bgImgRef = useCallback((gatsbyImg) => {
        if (!gatsbyImg) return;

        const img = gatsbyImg.imageRef.current;
        img.addEventListener('load', () => {
            setImgElem(img);
        });
        // we really only want this to run on the first render even
        // though it has a dependency on loadCanvas
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return bgImgRef;
};

export default useCanvasLoader;
