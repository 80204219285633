import {useCallback, useState} from 'react';

type EventHandler = (event: Event) => void;

export const useClickOutside = (callback: EventHandler) => {
    const [registered, setRegistered] = useState<boolean>(false);

    const outsideRef = useCallback(
        (node) => {
            const onOutsideClick = (event: Event) => {
                event.stopPropagation();
                if (node && !node.contains(event.target)) {
                    callback(event);
                }
            };

            if (!registered) {
                setRegistered(true);
                window.addEventListener('mousedown', onOutsideClick);
            }

            return () => {
                window.removeEventListener('mousedown', onOutsideClick);
            };
        },
        [callback, registered]
    );

    return outsideRef;
};
