import {RefObject, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '../../rootReducer';
import {PixelData, getColor, StrategyName, getDefaultPixel} from '../../../../common/imaging';
import {getContext} from '../../../../common/imaging/canvas';
import {useWindowDims} from './useWindowDims';
import debugSlice from '../../components/new-tab/SideBar/debugSlice';

const useBGInspector = (ref: RefObject<HTMLElement>, strategy: StrategyName) => {
    const dispatch = useDispatch();
    const [color, setColor] = useState<PixelData>(getDefaultPixel());
    const canvasRender = useSelector((state: RootState) => state.canvas.canvasRender);

    const windowDims = useWindowDims();

    useEffect(() => {
        const getImgData = (target: HTMLElement) => {
            const ctx = getContext();
            const rect = target.getBoundingClientRect();
            const {top, left, width, height} = rect;
            return ctx.getImageData(top, left, width, height);
        };

        if (ref && ref.current !== null) {
            const imgData = getImgData(ref.current);
            const c = getColor(imgData, strategy);
            setColor(c);
        }
    }, [ref, windowDims, strategy, canvasRender]);

    useEffect(() => {
        dispatch(debugSlice.actions.setLogoColor(color));
    }, [color, dispatch]);

    return color;
};

export default useBGInspector;
