export interface Dimension2D {
    width: number;
    height: number;
}

export type PixelData = {
    r: number;
    g: number;
    b: number;
    a: number;
};

export const getDefaultPixel = () => ({
    r: 0,
    b: 0,
    g: 0,
    a: 0
});

export const isDefaultPixel = (px: PixelData) => {
    return JSON.stringify(px) === JSON.stringify(getDefaultPixel());
};
