import {useEffect, useState} from 'react';

const getWindowDims = () => {
    return {
        width: window.innerWidth,
        height: window.innerHeight
    };
};

export const useWindowDims = () => {
    const [dims, setDims] = useState(getWindowDims);

    useEffect(() => {
        const onResize = () => {
            setDims(getWindowDims());
        };

        window.addEventListener('resize', onResize);

        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, []);

    return dims;
};

export const useOnWindowResize = (func: () => void) => {
    useEffect(() => {
        window.addEventListener('resize', func);

        return () => {
            window.removeEventListener('resize', func);
        };
    }, [func]);
};
