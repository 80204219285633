import React, {ReactElement, FC} from 'react';
import {Flex} from 'theme-ui';
import {css} from '@emotion/core';

type MapquestRoundIconProps = {
    color: string;
    icon: ReactElement;
    style?: ReturnType<typeof css>;
    radius?: number;
    onClick?: () => void;
};

const RoundIcon: FC<MapquestRoundIconProps> = ({color, icon, style, radius = 15, onClick}) => {
    const diameter = radius * 2;
    return (
        <Flex
            css={css`
                width: ${diameter}px;
                height: ${diameter}px;
                border-radius: ${radius}px;
                background-color: ${color};
                justify-content: center;
                align-items: center;
                margin-right: 14px;
                ${style}
            `}
            onClick={onClick}
        >
            {icon}
        </Flex>
    );
};

export default RoundIcon;
