import {createSelector} from '@reduxjs/toolkit';
import {RootState} from '../../sites/mapquest/rootReducer';

const installDateSelector = (state: RootState) => state.remoteConfig.installDate;
const pageLoadCountSelector = (state: RootState) => state.counts.pageLoadCount;
const windowClickSelector = (state: RootState) => state.counts.windowClickCount;

const thresHoldDate = new Date(2020, 11, 3, 12, 0, 0, 0);

export const isFirstLoadSelector = createSelector(
    [installDateSelector, pageLoadCountSelector],
    (installDateString, pageLoadCount) => {
        const date = thresHoldDate;
        const installDate = new Date(installDateString);
        return installDate > date && pageLoadCount === 1;
    }
);

export const isFirstWindowClickSelector = createSelector(
    [pageLoadCountSelector, windowClickSelector],
    (pageLoadCount, windowClickCount) => {
        return pageLoadCount === 1 && windowClickCount === 1;
    }
);

export const isSecondWindowClickSelector = createSelector(
    [pageLoadCountSelector, windowClickSelector],
    (pageLoadCount, windowClickCount) => {
        return pageLoadCount === 1 && windowClickCount === 2;
    }
);
