import React, {FC, useState} from 'react';
import {css, SerializedStyles} from '@emotion/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import {Collapse, List, ListItem, ListItemText} from '@material-ui/core';

type NestedListProps = {
    heading: string;
    children: React.ReactNodeArray;
    containerStyles: SerializedStyles;
};

const NestedList: FC<NestedListProps> = ({heading, children, containerStyles = null}) => {
    const [open, setOpen] = useState(true);
    const handleOpen = () => {
        setOpen(!open);
    };

    const muiNestedListHeadingTextCss = css`
        > span {
            font-size: 15px;
            font-weight: 500;
        }
    `;

    const muiNestedListHeadingChevronCss = css`
        margin-left: 5px;
    `;

    const muiNestedListItemTextCss = css`
        font-size: 12px;
        font-weight: 500;
    `;

    const muiNestedListToggleCss = css`
        margin: 0;
    `;

    const muiNestedListCollapseCss = css`
        padding-bottom: 35px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);

        ${containerStyles}

        &:last-of-type {
            padding-bottom: 0;
            border-bottom: none;
        }
    `;

    const muiNestedListHeadingCss = css`
        > div {
            ${muiNestedListHeadingTextCss};
        }

        > svg {
            ${muiNestedListHeadingChevronCss};
        }
    `;

    const muiNestedListCss = css`
        display: flex;

        .MuiCollapse-container {
            background-color: #f00;
            ${containerStyles}
        }

        > li > div > span {
            ${muiNestedListItemTextCss};
        }

        > li > span {
            ${muiNestedListToggleCss};
        }
    `;

    return (
        <>
            <ListItem button onClick={handleOpen} css={muiNestedListHeadingCss}>
                <ListItemText primary={heading} />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit css={muiNestedListCollapseCss}>
                <List disablePadding css={muiNestedListCss}>
                    {children}
                </List>
            </Collapse>
        </>
    );
};

export default NestedList;
