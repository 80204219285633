import {useEffect, useState} from 'react';
import * as yup from 'yup';
import {BrowserHistoryData, PageLoadEvent} from '@s1/extensions';
import {useLogging} from '../../../common/hooks/useLogging';

const HistorySchema = yup
    .array()
    .of(
        yup
            .object({
                url: yup.string().defined(),
                label: yup.string().defined()
            })
            .defined()
    )
    .defined();

type History = yup.InferType<typeof HistorySchema>;

export const useHistory = () => {
    const [history, setHistory] = useState<History>([]);

    const {ExtensionsErrorEvent} = useLogging();

    useEffect(() => {
        const historyHandler = new Promise<History>((resolve, reject) => {
            const handler = (evt: CustomEvent<BrowserHistoryData>) => {
                HistorySchema.validate(evt?.detail)
                    .then((data) => {
                        resolve(data);
                        setHistory(data);
                    })
                    .catch((e) => {
                        ExtensionsErrorEvent({type: 'useHistory', message: e.toString()});
                        reject();
                    });
            };
            window.addEventListener('browserHistory', handler as EventListener);
        });

        const timeoutWithDefaultValues = new Promise<History>((resolve) => {
            setTimeout(() => {
                resolve([
                    {
                        url: 'https://www.mapquest.com',
                        label: 'MapQuest'
                    },
                    {
                        url: 'https://www.howstuffworks.com',
                        label: 'HowStuffWorks'
                    },
                    {
                        url: 'https://www.kqzyfj.com/click-8402382-14103011?sid=mqnewtab',
                        label: 'Honey'
                    },
                    {
                        url: 'https://www.youtube.com',
                        label: 'YouTube'
                    },
                    {
                        url: 'https://www.startpage.com',
                        label: 'Startpage'
                    },
                    {
                        url: 'https://www.facebook.com',
                        label: 'Facebook'
                    }
                ]);
            }, 500);
        });

        const pageLoadEvent = PageLoadEvent();
        if (pageLoadEvent !== null) {
            window.dispatchEvent(pageLoadEvent);
        }
        Promise.race([historyHandler, timeoutWithDefaultValues]).then((data) => {
            setHistory(data);
        });
    }, [ExtensionsErrorEvent]);

    return history;
};
