/* @noflow */
import React, {useEffect, useState} from 'react';
import {Flex} from 'theme-ui';
import {css} from '@emotion/core';
import {useDispatch, useSelector} from 'react-redux';
import Checkbox from '../../assets/svg/checkbox.svg';
import {useLogging} from '../../../../common/hooks/useLogging';
import Arrow from '../../assets/svg/arrow.svg';
import {isFirstLoadSelector} from '../../../../common/selectors/index';

const KeepChanges = () => {
    const isFirstLoad = useSelector(isFirstLoadSelector);
    const [showAlert, setShowAlert] = useState<boolean>(false);
    const {InfoEvent} = useLogging();
    const dispatch = useDispatch();

    useEffect(() => {
        const handleClick = () => {
            setShowAlert(false);
        };

        if (isFirstLoad) {
            InfoEvent({type: 'KeepChangesAlert', message: 'open'});
            setShowAlert(true);
            window.addEventListener('click', handleClick);
        } else {
            setShowAlert(false);
        }
        return () => window.removeEventListener('click', handleClick);
    }, [dispatch, isFirstLoad, InfoEvent]);

    return showAlert ? (
        <>
            <Flex id="keep-changes">
                <Flex
                    css={`
                        display: block;
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        background-color: rgba(0, 0, 0, 0.6);
                        z-index: 19;
                        color: white;
                    `}
                />
            </Flex>
            <div
                css={`
                    background-color: #fff;
                    color: #080808;
                    width: 607px;
                    height: 340px;
                    position: absolute;
                    text-align: center;
                    font-family: Arial;
                    z-index: 20;
                    bottom: 5px;
                    display: block;
                    border-radius: 10px;
                    box-shadow: 0px 0px 20px 4px rgba(0, 0, 0, 0.45);
                    padding: 80px 10px 10px 10px;
                `}
            >
                <div>
                    <Arrow
                        css={css`
                            position: absolute;
                            margin-top: -165px;
                            margin-left: -15px;
                            animation-name: bounce;
                            animation-timing-function: ease;
                            transform-origin: bottom;
                            animation-duration: 1s;
                            animation-iteration-count: infinite;

                            @keyframes bounce {
                                0% {
                                    transform: translateY(0);
                                }
                                50% {
                                    transform: translateY(-100px);
                                }
                                100% {
                                    transform: translateY(0);
                                }
                            }
                        `}
                    />
                    <Checkbox />
                    <h3
                        css={css`
                            color: #0b8c24;
                            font-size: 26px;
                        `}
                    >
                        Mapquest Extension Successfully Installed!
                    </h3>
                    <div
                        css={css`
                            font-size: 22px;
                            font-weight: normal;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: 0.5em;
                            letter-spacing: normal;
                        `}
                    >
                        <p>
                            Click &ldquo;
                            <span
                                css={css`
                                    font-weight: bold;
                                `}
                            >
                                Keep It
                            </span>
                            &rdquo; to keep this Mapquest New Tab Extension
                        </p>
                        <p>with quick access to maps and directions.</p>
                    </div>
                </div>
            </div>
        </>
    ) : null;
};

export default KeepChanges;
