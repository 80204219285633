/* @noflow */
import React, {useState, FC, useCallback} from 'react';
import {Flex} from 'theme-ui';
import css from '@emotion/css';
import {useClickOutside} from '../../../../common/hooks/useClickOutside';

type VideoPopUpProps = {
    video: unknown;
    styles: string;
};

const VideoPopUp: FC<VideoPopUpProps> = ({video, styles}) => {
    const [showPopUp, setShowPopUp] = useState<boolean>(true);

    const onClickOutside = useCallback(() => {
        if (showPopUp) {
            setTimeout(() => {
                setShowPopUp(false);
            }, 500);
        }
    }, [showPopUp]);

    const clickOutsideRef = useClickOutside(onClickOutside);

    return showPopUp ? (
        <Flex>
            <video
                autoPlay
                muted
                ref={clickOutsideRef}
                src={video as string}
                css={css`
                    ${styles}
                `}
            >
                <track kind="captions" />
            </video>
        </Flex>
    ) : (
        <></>
    );
};

export default VideoPopUp;
