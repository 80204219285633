import React, {FC, ReactNode} from 'react';
import {css} from '@emotion/core';

type GridWrapperProps = {
    children?: ReactNode;
    style?: ReturnType<typeof css>;
};

export const GridWrapper: FC<GridWrapperProps> = ({children, style}) => {
    return (
        <div
            css={css`
                width: 100%;
                display: grid;
                grid-gap: 1rem;
                grid-template-columns: repeat(8, 1fr);
                grid-template-rows: [header] min-content [inputs] 1fr [footer] 1.5rem;
                ${style}
            `}
        >
            {children}
        </div>
    );
};
