import React, {FC, useContext, useEffect, useState} from 'react';
import {Flex, Text} from 'theme-ui';
import {css, SerializedStyles} from '@emotion/core';
import scrollbarStyle from 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {useSelector} from 'react-redux';
import Pin from '../../../assets/svg/new-tab/pin.svg';
import {useDebounce} from '../../../../../common/hooks/useDebounce';
import {fetchResults, Location} from '../mapquestApi';
import DirectionsContext from './ContextProvider';
import {coordinateSelector} from '../../../../../common/reducers/locationSlice';

type SuggestionProps = {
    location: Location;
    height: number;
    onClick: (location: Location) => void;
};

const Suggestion: FC<SuggestionProps> = ({location, height = 30, onClick}) => {
    return (
        <Flex
            css={css`
                width: 100%;
                height: ${height}px;
                flex-direction: row;
                align-items: center;
                padding: 14px 15px 11px;
                &:hover {
                    background-color: #f1f1f4;
                }
                cursor: pointer;
            `}
            className="suggestion"
            onClick={() => onClick(location)}
        >
            <Pin
                css={css`
                    margin-right: 14px;
                `}
            />
            <Flex
                css={css`
                    flex-direction: column;
                    font-size: 12px;
                    line-height: 0.8rem;

                    .type {
                        opacity: 0.4;
                    }
                `}
            >
                <Text>{location.displayString}</Text>
                <Text className="type">Address</Text>
            </Flex>
        </Flex>
    );
};

type SuggestionListProps = {
    query: string;
    onClick: (location: Location) => void;
    cssOverrides?: SerializedStyles;
};

const useGetLocations = (query: string) => {
    const [addresses, setAddresses] = useState<Location[]>([]);
    const {latitude, longitude} = useSelector(coordinateSelector);
    const debounceQuery = useDebounce<string>(query, 135);
    useEffect(() => {
        fetchResults(debounceQuery, {latitude, longitude}).then((results) => {
            setAddresses(results);
        });
    }, [debounceQuery, latitude, longitude]);

    return addresses;
};

const SuggestionList: FC<SuggestionListProps> = ({query, onClick, cssOverrides}) => {
    const {showSuggestions} = useContext(DirectionsContext);
    const locations = useGetLocations(query);

    return (showSuggestions && locations.length) > 0 ? (
        <PerfectScrollbar
            css={[
                css`
                    z-index: 10;
                    width: 100%;
                    border-radius: 10px;
                    background-color: #ffffff;

                    > .suggestion:first-child {
                        padding-top: 14px;
                    }

                    ${scrollbarStyle};
                    height: unset;
                `,
                cssOverrides
            ]}
            className="suggest-container"
        >
            {locations.map((location) => (
                <Suggestion key={location.displayString} location={location} onClick={onClick} height={34} />
            ))}
        </PerfectScrollbar>
    ) : null;
};

type LocationSuggestProps = {
    cssOverrides?: SerializedStyles;
};

export const LocationSuggest: FC<LocationSuggestProps> = ({cssOverrides}) => {
    const ctx = useContext<DirectionsContext>(DirectionsContext);
    const locationName = ctx.activeSearchBar;
    return (
        <SuggestionList
            query={ctx[locationName]}
            onClick={(location) => {
                ctx.setLocationSatisfiedState({[locationName]: true});
                ctx.handleInputChange({[locationName]: location.displayString});
                ctx.updateDirectionUrl({[locationName]: location.salt || location.slug});
                ctx.setShowSuggestions(false);
            }}
            cssOverrides={cssOverrides}
        />
    );
};
