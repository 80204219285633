import React, {FC} from 'react';

import {css} from '@emotion/core';

import {DirectionsContextProvider} from './ContextProvider';
import {CategoriesFragment} from './CategoriesFragment';
import DirectionsFragment from './DirectionsFragment';
import {GridWrapper} from '../GridWrapper';

const DirectionsSearch: FC<{}> = () => {
    return (
        <DirectionsContextProvider>
            <form
                css={css`
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: center;
                `}
                onSubmit={(e) => {
                    // Go to mapquest.com
                    e.preventDefault();
                }}
            >
                <GridWrapper
                    style={css`
                        position: relative;
                        grid-template-columns:
                            [grid-start] 0.5rem
                            [col-start] 0.4rem
                            [gutter-left] repeat(11, 1fr) [gutter-right]
                            0.65rem [col-end]
                            0.5rem
                            0.5rem [grid-end];
                        row-gap: 0.7rem;
                        column-gap: 0.3rem;
                        grid-auto-rows: minmax(26px, auto);

                        input {
                            height: 24px;
                        }

                        input,
                        .main-content {
                            grid-column: gutter-left / gutter-right;
                        }

                        .reverse {
                            grid-column: gutter-right / grid-end;
                            grid-row: 3 / 5;
                            width: 1.5rem;
                            justify-content: center;
                            display: flex;
                            margin: auto;
                        }

                        .suggest-container {
                            grid-column: gutter-left / gutter-right;
                            grid-row: 5;
                            position: absolute;
                        }
                    `}
                >
                    <DirectionsFragment />
                    <CategoriesFragment />
                </GridWrapper>
            </form>
        </DirectionsContextProvider>
    );
};

export default DirectionsSearch;
