import React, {FC, useContext, useState, useEffect} from 'react';
import {Flex, Text, IconButton, Box} from 'theme-ui';
import {css} from '@emotion/core';
import {animated, useTransition} from 'react-spring';
import {useSelector} from 'react-redux';
import {NewTabContext} from '../NewTabThemeProvider';
import {useLogging} from '../../../../../common/hooks/useLogging';
import Redirect from '../../../util/redirect';
import {RootState} from '../../../rootReducer';

type IconProps = {
    icon: string;
    onClick: (evt: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    label: string;
};

const getFavicon = (url: string) => {
    return `https://www.google.com/s2/favicons?sz=64&domain_url=${encodeURI(url)}`;
};

type MissingIconProps = {
    label: string;
    radius: number;
};

const MissingIcon: FC<MissingIconProps> = ({label, radius}) => {
    const firstCharacter = label.slice(0, 1);
    return (
        <Flex
            css={css`
                background-color: #3c75c4;
                border-radius: ${radius}px;
                width: ${radius / 2}px;
                height: ${radius / 2}px;
                align-items: center;
                justify-content: center;
            `}
        >
            <Text
                css={css`
                    color: #fff;
                `}
            >
                {firstCharacter}
            </Text>
        </Flex>
    );
};

const Icon: FC<IconProps> = ({icon, onClick, label}) => {
    const [hover, setHover] = useState(false);
    const {fontColor} = useContext(NewTabContext);
    const [iconLoaded, setIconLoaded] = useState(true);
    const showMissingFavicons = useSelector((state: RootState) => state.debug.showMissingFavicons);

    const onImgError = () => {
        setIconLoaded(false);
    };

    return (
        <Flex
            css={css`
                flex-direction: column;
                align-items: center;
                justify-content: center;
                position: relative;
                width: 95px;
                height: 121px;
                &:hover {
                    cursor: pointer;
                }
            `}
            onClick={onClick}
            onMouseEnter={() => {
                if (!hover) {
                    setHover(true);
                }
            }}
            onMouseLeave={() => {
                if (hover) {
                    setHover(false);
                }
            }}
        >
            <IconButton
                css={css`
                    width: 50px;
                    height: 50px;
                    opacity: 1;
                    border-radius: 15px;
                    background-color: #ffffff;
                    outline: none;
                    &:hover {
                        cursor: pointer;
                    }
                `}
            >
                {iconLoaded && !showMissingFavicons ? (
                    <img
                        css={css`
                            width: 24px;
                            height: 24px;
                        `}
                        src={icon}
                        onError={onImgError}
                        alt={label}
                    />
                ) : (
                    <MissingIcon radius={50} label={label} />
                )}
            </IconButton>
            <Text
                css={css`
                    margin-top: 10px;
                    color: ${fontColor};
                    font-size: 12px;
                    text-align: center;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    width: 90px;
                `}
            >
                {label}
            </Text>
            <Box
                css={css`
                    display: ${hover ? 'box' : 'none'};
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    z-index: -1;
                `}
            >
                <Box
                    css={css`
                        width: 100%;
                        height: 100%;
                        opacity: 0.28;
                        background-color: #fff;
                        border-radius: 10px;
                    `}
                />
            </Box>
        </Flex>
    );
};

type SpeedDialItem = {
    iconUrl?: string;
    url: string;
    label: string;
};

type SpeedDialProps = {items: SpeedDialItem[]; title: string};

const SpeedDial: FC<SpeedDialProps> = ({items, title}) => {
    const {fontColor} = useContext(NewTabContext);
    const [speedDialItems, setSpeedDialItems] = useState(items);
    const {ClickEvent} = useLogging();

    const speedDialTransition = useTransition(speedDialItems, (item) => item.label, {
        from: {opacity: 0},
        enter: {opacity: 1},
        leave: {opacity: 0},
        config: {tension: 220, friction: 10}
    });

    useEffect(() => {
        setSpeedDialItems(items);
    }, [items]);
    return (
        <Flex
            css={css`
                flex-direction: column;
            `}
        >
            <Flex
                css={css`
                    min-width: 114px;
                    width: 114px;
                    height: 35px;
                    border-radius: 17.5px;
                    background-color: rgba(0, 0, 0, 0.13);
                    justify-content: center;
                    align-items: center;
                    margin: 0 0 8px 0;
                `}
            >
                <Text
                    css={css`
                        font-size: 12px;
                        text-transform: uppercase;
                        color: ${fontColor};
                    `}
                >
                    {title}
                </Text>
            </Flex>
            <Flex
                css={css`
                    width: 100%;
                    justify-content: space-between;
                `}
            >
                {speedDialTransition.map(({item, props, key}) => (
                    <animated.div style={props} key={item.url + item.label}>
                        <Icon
                            label={item.label}
                            onClick={() => {
                                ClickEvent({type: title, page: key.toString()}).then(() => {
                                    Redirect(item.url);
                                });
                            }}
                            icon={item.iconUrl ? item.iconUrl : getFavicon(item.url)}
                        />
                    </animated.div>
                ))}
            </Flex>
        </Flex>
    );
};

export default SpeedDial;
