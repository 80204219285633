import React, {FC, useRef} from 'react';
import {css, SerializedStyles} from '@emotion/core';
import {useSelector} from 'react-redux';
import MapQuestWhiteSvg from '../assets/svg/mapquest-logo-white.svg';
import {isDefaultPixel, PixelData} from '../../../common/imaging/types';
import {useBGInspector} from '../hooks/background-inspector';
import {RootState} from '../rootReducer';

const getStyles = (fill = 'transparent'): SerializedStyles => {
    return css`
        margin: 21px;
        height: 24px;
        path {
            transition: fill 0.3s ease-in;
            fill: ${fill};
        }
    `;
};

const getLogoCss = (color: PixelData) => {
    const getColorString = (px: PixelData): string | undefined => {
        return isDefaultPixel(px)
            ? undefined
            : `rgba(${Math.round(px.r)}, ${Math.round(px.g)}, ${Math.round(px.b)}, ${Math.round(px.a)})`;
    };

    const fillStyles = getColorString(color);
    const styles = getStyles(fillStyles);
    return styles;
};

export const MapQuestLogo: FC = () => {
    const {useAvgColor} = useSelector((state: RootState) => state.debug);

    const containerRef = useRef<HTMLDivElement>(null);
    const bgColor = useBGInspector(containerRef, useAvgColor ? 'average' : 'monotone');

    return (
        <a
            href="https://www.mapquest.com"
            css={css`
                z-index: 1;
            `}
            id="logo-link"
        >
            <div
                ref={containerRef}
                css={css`
                    position: relative;
                    filter: contrast(4);
                `}
                id="logo-wrapper"
            >
                <MapQuestWhiteSvg css={getLogoCss(bgColor)} />
            </div>
        </a>
    );
};
