import * as yup from 'yup';
import {Coords} from '../../../../common/reducers/locationSlice';

const config = {
    key: 'Cmjtd%7Cluur2108n1,7w%3Do5-gz8a',
    apiUrl: 'https://temp.mapquestapi.com/search/v3/prediction'
};

const PlaceSchema = yup.object({
    street: yup.string().optional(),
    city: yup.string().optional(),
    stateCode: yup.string().optional(),
    postalCode: yup.string().optional()
});

const LocationSchema = yup
    .object({
        id: yup.string().defined(),
        name: yup.string().defined(),
        displayString: yup.string().defined(),
        place: yup
            .object({
                properties: PlaceSchema
            })
            .optional(),
        slug: yup.string().required(),
        salt: yup.string().optional()
    })
    .defined();

const ResponseSchema = yup
    .object({
        results: yup
            .array()
            .of(LocationSchema)
            // .compact((l) => l?.slug === undefined)
            .ensure()
            .defined()
    })
    .defined();

export type Location = yup.InferType<typeof LocationSchema>;

type Response = yup.InferType<typeof ResponseSchema>;

const buildSearchUrl = (query: string, geocode: Coords) => {
    const collections = ['address', 'adminArea', 'airport', 'poi'];
    const apiUrl = new URL(`${config.apiUrl}/?key=${config.key}`);
    apiUrl.searchParams.append('q', query);
    apiUrl.searchParams.append('limit', '7');
    apiUrl.searchParams.append('countryCode', 'US');
    apiUrl.searchParams.append('feedback', 'false');
    apiUrl.searchParams.append(
        'collection',
        collections.reduce((prev, cur) => {
            return `${prev},${cur}`;
        })
    );
    if (geocode) {
        apiUrl.searchParams.append('location', `${geocode.longitude},${geocode.latitude}`);
    }
    return apiUrl.toString();
};

export const fetchResults = async (query: string, geocode: Coords) => {
    if (query.length < 2 || query.length > 100) {
        return [];
    }
    const url = buildSearchUrl(query, geocode);
    const resp = await fetch(url);
    const json = await resp.json();
    let validatedResults: Response = {results: []};

    try {
        validatedResults = await ResponseSchema.validate(json, {
            stripUnknown: true,
            strict: false // cast fields to type in schema
        });
    } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
    }
    return validatedResults.results.map((result) => result);
};
