import React, {ChangeEvent, FC, useState, useEffect} from 'react';
import {css} from '@emotion/core';
import {Flex} from 'theme-ui';
import * as yup from 'yup';
import {Divider} from '@material-ui/core';
import MagnifyingGlass from '../../assets/svg/magnifying-glass-icon.svg';
import {useDebounce} from '../../../../common/hooks/useDebounce';

type SearchBarState = {
    query: string;
};

type PrivacySearchBarProps = {
    placeholder: string;
    onSubmit: (state: SearchBarState) => void;
    // eslint-disable-next-line
    searchStyle?: ReturnType<typeof css>;
    inputStyle?: ReturnType<typeof css>;
};

type AutoSuggestItemProps = {
    onClick: (value: string) => void;
    value: string;
    onSubmit: (state: SearchBarState) => void;
};

const SearchBarResetCSS = css`
    input {
        &:focus {
            outline: none;
        }
        background-color: #fff;
    }
    button {
        border: 0;
        outline: 0;
        cursor: pointer;
        margin: 0;
    }
`;

const AutoSuggestButtonCSS = css`
    border: 0;
    outline: 0;
    cursor: pointer;
    margin: 0;
    font-size: 20px;
    color: #6e6e6e;
    font-family: 'Roboto', sans-serif;
    background-color: #fff;
    padding: 0;
`;

const SearchSuggestionSchema = yup
    .object({
        text: yup.string().defined()
    })
    .defined();
const SearchSuggestionsSchema = yup.array().of(SearchSuggestionSchema).defined();
type searchSuggestion = yup.InferType<typeof SearchSuggestionSchema>;
type searchSuggestions = yup.InferType<typeof SearchSuggestionsSchema>;

const AutoSuggestItem = ({onClick, value, onSubmit}: AutoSuggestItemProps) => {
    const handleClick = () => {
        const query = value;
        onClick(value);
        onSubmit({query});
    };

    return (
        <div
            css={css`
                display: flex;
                width: 100%;
                line-height: 35px;
                padding: 5px 0 5px 21px;
                :hover {
                    background-color: #f1f1f4;
                    cursor: pointer;
                    button {
                        background-color: #f1f1f4;
                        cursor: pointer;
                        width: 90%;
                        text-align: left;
                    }
                }
            `}
        >
            <button onClick={handleClick} type="submit" value={value} css={AutoSuggestButtonCSS}>
                {value}{' '}
            </button>
        </div>
    );
};

const PrivateSearchBar: FC<PrivacySearchBarProps> = ({placeholder, inputStyle, onSubmit}) => {
    const [query, setQuery] = useState('');
    const [searchSuggestions, setSearchSuggestions] = useState([{text: ''}]);
    const [searchSuggestionsActive, setSearchSuggestionsActive] = useState(false);
    const minQueryLength = 2;

    useEffect(() => {
        setSearchSuggestionsActive(query.length > minQueryLength && searchSuggestions.length > 1);
    }, [query.length, searchSuggestions.length]);

    function getAutocompleteSuggestions(qry: string) {
        if (qry.length > minQueryLength) {
            fetch(`https://ext.mapquest.com/suggestions?q=${qry}&segment=mq.extension.yhs01`)
                .then((response) => response.json())
                .then((results) => {
                    const schema = SearchSuggestionsSchema;
                    schema
                        .validate(results.suggestions)
                        .then((data) => {
                            const suggestions = data.slice(0, 8);
                            setSearchSuggestions(suggestions);
                        })
                        .catch((err) => {
                            // eslint-disable-next-line no-console
                            console.error(err);
                        });
                })
                .catch((err) => {
                    // eslint-disable-next-line no-console
                    console.error(err);
                });
        }
    }

    const debounceQuery = useDebounce(query, 300);

    const onAutoSuggestClick = (value: string) => {
        setQuery(value);
    };

    const handleMagnifyingGlassClick = () => {
        if (query) onSubmit({query});
    };

    const handleQueryChange = (event: ChangeEvent<HTMLInputElement>) => {
        setQuery(event.target.value);
    };

    useEffect(() => {
        getAutocompleteSuggestions(debounceQuery);
        if (!query) {
            // close search suggestions if no query
            setSearchSuggestions([{text: ''}]);
            setSearchSuggestionsActive(false);
        }
    }, [debounceQuery, setSearchSuggestions, setSearchSuggestionsActive, query]);

    return (
        <aside>
            <form
                css={css`
                    ${SearchBarResetCSS};
                    border-radius: ${searchSuggestionsActive ? '22.5px 22.5px 0 0' : '22.5px'};
                    box-shadow: rgb(217, 217, 217) 0px 0px 1px 1px;
                    display: flex;
                    background-color: #fff;
                    justify-content: space-between;
                    flex-direction: row;
                    height: 45px;
                    overflow: hidden;
                    box-sizing: content-box;
                    padding: 0 0 0 21px;
                `}
                onSubmit={(e) => {
                    e.preventDefault();
                    onSubmit({query});
                }}
            >
                <Flex
                    css={css`
                        width: 100%;
                        align-items: center;
                    `}
                >
                    <input
                        placeholder={placeholder}
                        type="input"
                        name="query"
                        // eslint-disable-next-line jsx-a11y/no-autofocus
                        autoFocus
                        value={query}
                        autoComplete="off"
                        onChange={handleQueryChange}
                        css={css`
                            padding: 0;
                            border: none;
                            box-shadow: none;
                            width: 100%;
                            background-color: #fff;
                            font-size: 20px;
                            font-family: 'Roboto', sans-serif;
                            ${inputStyle};
                        `}
                    />
                    <MagnifyingGlass
                        css={css`
                            height: 100%;
                            margin-right: 20px;
                            :hover {
                                cursor: pointer;
                            }
                        `}
                        onClick={handleMagnifyingGlassClick}
                    />
                </Flex>
            </form>
            <Flex
                css={css`
                    display: ${searchSuggestionsActive ? 'flex' : 'none'};
                    flex-direction: column;
                    box-shadow: 0px 2px 2px 0px #ccc;
                    border-bottom-right-radius: 22.5px;
                    border-bottom-left-radius: 22.5px;
                    z-index: 2;
                    background-color: #fff;
                    position: absolute;
                    width: 677px;
                    overflow: hidden;
                `}
            >
                <Divider />
                <Flex
                    css={css`
                        flex-direction: column;
                        width: 100%;
                    `}
                >
                    {searchSuggestions
                        .filter((value) => value.text !== '')
                        .map((value) => {
                            return (
                                <AutoSuggestItem
                                    onClick={onAutoSuggestClick}
                                    value={value.text}
                                    key={value.text}
                                    onSubmit={onSubmit}
                                />
                            );
                        })}
                </Flex>
            </Flex>
        </aside>
    );
};

export default PrivateSearchBar;
