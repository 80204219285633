import React, {ChangeEvent, FC, useContext, useEffect, useState} from 'react';
import {Input} from 'theme-ui';
import {css, SerializedStyles} from '@emotion/core';
import DirectionsContext, {LocationName} from './ContextProvider';

export type DirectionsInputProps = {
    query: string;
    name: string;
    placeholder: string;
    onFocus?: (evt: React.FocusEvent<HTMLInputElement>) => void;
    onBlur?: (evt: React.FocusEvent<HTMLInputElement>) => void;
    autoFocus?: boolean;
    cssOverrides?: SerializedStyles;
};

export const DirectionsInput: FC<DirectionsInputProps> = ({
    query,
    name,
    placeholder,
    onFocus,
    onBlur,
    autoFocus,
    cssOverrides
}) => {
    const {handleInputChange, setLocationSatisfiedState} = useContext(DirectionsContext);

    const [directionsQuery, setDirectionsQuery] = useState('');

    useEffect(() => {
        setDirectionsQuery(query);
    }, [query]);

    const handleChange = (evt: ChangeEvent<HTMLInputElement>) => {
        const {value} = evt.target;
        if (!value || value === '') {
            setLocationSatisfiedState({[name]: false});
        }

        handleInputChange({[name]: value});
        setDirectionsQuery(value);
    };

    return (
        <Input
            css={[
                css`
                    border-radius: 10px;
                    background-color: #ffffff;
                    border: solid 1px #dfdfdf;
                    padding: 10px;
                    font-size: 12px;
                    width: 100%;
                    &:focus {
                        border: solid 1px #007aff;
                        outline: none;
                    }
                    &::placeholder {
                        opacity: 0.38;
                        font-style: italic;
                        font-color: #000;
                    }
                `,
                cssOverrides
            ]}
            value={directionsQuery}
            name={name}
            onFocus={onFocus}
            autoFocus={autoFocus}
            onBlur={onBlur}
            placeholder={placeholder}
            onChange={handleChange}
            autoComplete="off"
        />
    );
};

type LocationInputProps = {
    name: LocationName;
    placeholder?: string;
    autoFocus?: boolean;
    cssOverrides?: SerializedStyles;
};

const LocationInput: FC<LocationInputProps> = ({name, placeholder, autoFocus, cssOverrides}) => {
    const ctx = useContext<DirectionsContext>(DirectionsContext);
    const ph = placeholder !== undefined ? placeholder : name.charAt(0).toUpperCase() + name.substring(1);
    return (
        <DirectionsInput
            query={ctx[name]}
            name={name}
            placeholder={ph}
            onFocus={() => {
                ctx.setActiveSearchBar(name);
            }}
            autoFocus={autoFocus}
            cssOverrides={cssOverrides}
        />
    );
};

export default LocationInput;
