import * as yup from 'yup';
import React, {createContext, FC, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {Helmet} from 'react-helmet';
import {useStickyState} from '../../../../common/hooks/useStickyState';
import canvasSlice from '../../hooks/background-inspector/canvasSlice';

const NewTabThemeSchema = yup
    .object({
        name: yup.string().required().defined(),
        bgColor: yup.string().required().defined(),
        fontColor: yup.string().required().defined()
    })
    .defined()
    .nullable(false);
type NewTabTheme = yup.InferType<typeof NewTabThemeSchema>;

const colorThemes: NewTabTheme[] = [
    {name: 'dodger blue', bgColor: '#4286f6', fontColor: '#fff'},
    {name: 'cerulean', bgColor: '#02aaf5', fontColor: '#fff'},
    {name: "robin's egg blue", bgColor: '#00bdd5', fontColor: '#fff'},
    {name: 'san marino', bgColor: '#3e52b5', fontColor: '#fff'},
    {name: 'royal purple', bgColor: '#673bb8', fontColor: '#fff'},
    {name: 'seance', bgColor: '#9d28b1', fontColor: '#fff'},
    {name: 'cold purple', bgColor: '#b29bd9', fontColor: '#fff'},
    {name: 'persian green', bgColor: '#009788', fontColor: '#fff'},
    {name: 'tickle me pink', bgColor: '#ff7590', fontColor: '#fff'},
    {name: 'your pink', bgColor: '#fec5c6', fontColor: '#fff'},
    {name: 'zombie', bgColor: '#e8d2a8', fontColor: '#fff'},
    {name: 'lynch', bgColor: '#607e8c', fontColor: '#fff'},
    {name: 'porcelain', bgColor: '#f6f8f9', fontColor: '#0a0916'},
    {name: 'big stone', bgColor: '#132c37', fontColor: '#fff'}
];
const photoGalleryTheme: NewTabTheme = {name: 'photo gallery', bgColor: 'initial', fontColor: '#fff'};

type NewTabThemeProviderType = {
    children: React.ReactNode;
};

export const NewTabContext = createContext({
    showMostVisited: true,
    showPopular: true,
    showPhotoGallery: true,
    newPhotoEveryTime: false,
    colorThemes,
    colorTheme: colorThemes[0],
    bgColor: '',
    fontColor: '',
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    toggleShowMostVisited: () => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    toggleShowPopular: () => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    toggleShowPhotoGallery: () => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    toggleShowNewPhotoEveryTime: () => {},
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
    setColorTheme: (color: NewTabTheme) => {}
});

const NewTabThemeProvider: FC<NewTabThemeProviderType> = ({children}) => {
    const [showMostVisited, setShowMostVisited] = useStickyState<boolean>('showMostVisited', (v) => {
        return typeof v === 'boolean' ? v : true;
    });
    const [showPopular, setShowPopular] = useStickyState<boolean>('showPopular', (v) => {
        return typeof v === 'boolean' ? v : true;
    });
    const [showPhotoGallery, setShowPhotoGallery] = useStickyState<boolean>('showPhotoGallery', (v) => {
        return typeof v === 'boolean' ? v : true;
    });
    const [newPhotoEveryTime, setNewPhotoEveryTime] = useStickyState<boolean>('newPhotoEveryTime', (v) => {
        return typeof v === 'boolean' ? v : false;
    });
    const [colorTheme, setColorTheme] = useStickyState<NewTabTheme>('colorTheme', (v) => {
        try {
            return NewTabThemeSchema.validateSync(v, {strict: true});
        } catch (err) {
            return colorThemes[0];
        }
    });

    const resolveBgColor = () => {
        return showPhotoGallery ? photoGalleryTheme.bgColor : colorTheme.bgColor;
    };
    const resolveFontColor = () => {
        return showPhotoGallery ? photoGalleryTheme.fontColor : colorTheme.fontColor;
    };

    const dispatch = useDispatch();
    useEffect(() => {
        const bgState = showPhotoGallery ? 'backgroundImage' : colorTheme.bgColor;
        dispatch(canvasSlice.actions.setBackgroundState(bgState));
    }, [showPhotoGallery, colorTheme.bgColor, dispatch]);

    const [oneTrustHeadElements, setOneTrustHeadElements] = useState(<></>);

    useEffect(() => {
        const oneTrustId: string = window.location.hostname.includes('localhost')
            ? 'efbfe601-8324-4dfc-92c4-a6bbc3c47877-test'
            : 'efbfe601-8324-4dfc-92c4-a6bbc3c47877';

        setOneTrustHeadElements(
            <Helmet>
                <script
                    src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
                    type="text/javascript"
                    charSet="UTF-8"
                    data-domain-script={oneTrustId}
                />
                <script type="text/javascript">{`function OptanonWrapper() {}`}</script>
            </Helmet>
        );
    }, []);

    return (
        <>
            {oneTrustHeadElements}
            <NewTabContext.Provider
                value={{
                    showMostVisited,
                    showPopular,
                    showPhotoGallery,
                    newPhotoEveryTime,
                    colorThemes,
                    colorTheme,
                    bgColor: resolveBgColor(),
                    fontColor: resolveFontColor(),
                    toggleShowMostVisited: () => {
                        setShowMostVisited(!showMostVisited);
                    },
                    toggleShowPopular: () => {
                        setShowPopular(!showPopular);
                    },
                    toggleShowPhotoGallery: () => {
                        setShowPhotoGallery(!showPhotoGallery);
                        dispatch(canvasSlice.actions.setBackgroundState('image'));
                    },
                    toggleShowNewPhotoEveryTime: () => {
                        setNewPhotoEveryTime(!newPhotoEveryTime);
                    },
                    setColorTheme: (theme) => {
                        setColorTheme(theme);
                    }
                }}
            >
                {children}
            </NewTabContext.Provider>
        </>
    );
};

export default NewTabThemeProvider;
