import React, {FC} from 'react';
import {Flex, Text} from 'theme-ui';
import {css} from '@emotion/core';
import {animated, useSpring, config} from 'react-spring';
import ChevronDown from '../../assets/svg/new-tab/chevron_down.svg';
import MapquestIcon from '../../assets/svg/mapquest-icon-white.svg';
import DirectionsSearch from './Directions';
import RoundIcon from './RoundIcon';

type BottomMenuProps = {
    title: string;
    collapsed?: boolean;
    onClickToggle: () => void;
};

const DirectionsPanel: FC = () => {
    return (
        <Flex
            css={css`
                width: 100%;
                flex-direction: column;
                background-color: #f9f9fc;
                height: 100%;
                padding-top: 19px;
                border-radius: 12px 12px 0 0;
            `}
        >
            <DirectionsSearch />
        </Flex>
    );
};

const BottomMenu: React.ForwardRefRenderFunction<HTMLDivElement, BottomMenuProps> = (
    {title, onClickToggle, collapsed = false},
    ref
) => {
    const containerHeight = 512; // px
    const overHang = 100;
    const headerHeight = 65; // px
    const props = useSpring({
        transform: `translateY(${collapsed ? containerHeight - headerHeight : 0}px)`,
        config: config.stiff
    });

    const railSize = 34; // px

    return (
        <animated.div
            ref={ref}
            style={props}
            css={css`
                opacity: 100%;
                width: 677px;
                height: ${containerHeight + overHang}px;
                border-radius: 26px 26px 0 0;
                background-color: #ffffff;
                padding: 0;
                position: fixed;
                bottom: -${overHang}px;
                z-index: 11;
                color: #000;
                overflow: hidden;
            `}
        >
            <Flex
                css={css`
                    align-items: center;
                    padding: 17px ${railSize}px 27px ${railSize}px;
                    cursor: pointer;
                `}
                onClick={onClickToggle}
            >
                <RoundIcon color={collapsed ? '#007aff' : '#acb3bb'} icon={<MapquestIcon height={14} />} />
                <Text
                    css={css`
                        text-transform: uppercase;
                        font-size: 14px;
                    `}
                >
                    {title}
                </Text>
                <Flex
                    css={css`
                        flex: 1 1 0;
                    `}
                />
                <ChevronDown
                    css={css`
                        ${collapsed ? 'transform: scaleY(-1);' : ''}
                    `}
                />
            </Flex>
            <Flex
                css={css`
                    height: 100%;
                    flex-direction: row;
                    align-items: flex-end;
                    margin: 0 ${railSize}px;
                `}
            >
                <DirectionsPanel />
            </Flex>
        </animated.div>
    );
};

export default React.forwardRef(BottomMenu);
