import React, {FC, useEffect, useState} from 'react';
import {Flex, Text, Button, Box} from 'theme-ui';
import {Global, css} from '@emotion/core';
import {DialogOverlay, DialogContent} from '@reach/dialog';
import styles from '@reach/dialog/styles.css';
import {useSelector} from 'react-redux';
import UpdateArtwork from '../../assets/svg/new-tab/update-artwork.svg';
import {useStickyState} from '../../../../common/hooks/useStickyState';
import {RootState} from '../../rootReducer';

const UpdateModal: FC = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [shown, setShown] = useStickyState<boolean>('updateModalShown', (v) => (typeof v === 'boolean' ? v : false));
    const isUpdate = useSelector((state: RootState) => state.remoteConfig.update);

    const debugShown = useSelector((state: RootState) => state.debug.showUpdateModal);

    const close = () => {
        setIsOpen(false);
        setShown(true);
    };

    useEffect(() => {
        setIsOpen((isUpdate && !shown) || debugShown);
    }, [debugShown, isUpdate, shown]);

    return (
        <DialogOverlay
            css={css`
                z-index: 1000000;
            `}
            style={{background: 'rgba(0, 0, 0, .5)'}}
            isOpen={isOpen}
            onDismiss={close}
        >
            <Global styles={styles} />
            <Box
                css={css`
                    margin: 0;
                    min-width: 0;
                    max-width: 1366px;
                    width: 100%;
                    display: flex;
                    height: 100%;
                    display: flex;
                    margin: auto;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                `}
            >
                <DialogContent
                    css={css`
                        padding: 35px;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-around;
                        align-items: center;
                        width: 375px;
                        height: 451px;
                        border-radius: 14px;
                        background-color: #fff;
                        color: #000;
                        margin: 0;
                        padding-top: 0;
                    `}
                >
                    <Flex
                        css={css`
                            max-height: 43px;
                            min-height: 0;
                            height: 43px;
                            flex: 1 1 43px;
                        `}
                    />
                    <UpdateArtwork />
                    <Text
                        as="h3"
                        css={css`
                            font-family: 'Roboto';
                            font-size: 20px;
                            font-weight: bold;
                            letter-spacing: -0.53px;
                            text-align: center;
                            margin: 39px 0 23px 0;
                        `}
                    >
                        New and Improved
                    </Text>
                    <Text
                        css={css`
                            font-size: 14px;
                            font-weight: normal;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: 1.21;
                            letter-spacing: -0.37px;
                            text-align: center;
                            margin-bottom: 39px;
                        `}
                    >
                        Hey, we&apos;ve made the MapQuest New Tab experience even better. Daily background images or
                        choose a solid color. Easier access to directions
                    </Text>
                    <Button
                        css={css`
                            outline: none;
                            width: 217px;
                            height: 51px;
                            border-radius: 25.5px;
                            background-color: #007aff;
                            &:hover {
                                cursor: pointer;
                            }
                        `}
                        onClick={close}
                    >
                        <Text
                            css={css`
                                font-family: Roboto;
                                font-size: 14px;
                                font-weight: normal;
                                font-stretch: normal;
                                font-style: normal;
                                line-height: normal;
                                letter-spacing: -0.37px;
                                text-align: center;
                                color: #ffffff;
                            `}
                        >
                            Great, let me try it
                        </Text>
                    </Button>
                </DialogContent>
            </Box>
        </DialogOverlay>
    );
};

export default UpdateModal;
