import React, {useContext, useEffect, useState} from 'react';
import {Drawer, List, ListItem, ListItemText, IconButton} from '@material-ui/core';
import TuneRoundedIcon from '@material-ui/icons/TuneRounded';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import {css} from '@emotion/core';
import {useLocation, WindowLocation} from '@reach/router';
import {useDispatch, useSelector} from 'react-redux';
import Toggle from '../Toggle';
import {NewTabContext} from '../../../components/new-tab/NewTabThemeProvider';
import ColorSelect from './ColorSelect';
import NestedList from './NestedList';
import debugSlice from './debugSlice';
import {RootState} from '../../../rootReducer';

const DebugMenu = () => {
    const shouldShow = (location: WindowLocation) => {
        if (!location.search) {
            return false;
        }
        const params = new URLSearchParams(location.search);
        return params.has('debug');
    };

    const debug = useSelector((state: RootState) => state.debug);
    const dispatch = useDispatch();

    return shouldShow(useLocation()) ? (
        <NestedList
            heading="DEBUG"
            containerStyles={css`
                display: flex;
                flex-grow: 1;

                .hidden {
                    display: none;
                }
            `}
        >
            <ListItem key="new photo every time">
                <ListItemText primary="New Photo Every Time" />
                <Toggle
                    checked={debug.newPhotoEveryTime}
                    onChange={() => dispatch(debugSlice.actions.newPhotoEveryTime(!debug.newPhotoEveryTime))}
                />
            </ListItem>
            <ListItem key="show update modal">
                <ListItemText primary="Show Update Modal" />
                <Toggle
                    checked={debug.showUpdateModal}
                    onChange={() => dispatch(debugSlice.actions.showUpdateModal(!debug.showUpdateModal))}
                />
            </ListItem>
            <ListItem key="Show Missing Favicon">
                <ListItemText primary="Show Missing Favicon" />
                <Toggle
                    checked={debug.showMissingFavicons}
                    onChange={() => dispatch(debugSlice.actions.showMissingFavicons(!debug.showMissingFavicons))}
                />
            </ListItem>
            <ListItem key="show canvas overlay">
                <ListItemText primary="Show Canvas Overlay" />
                <Toggle
                    checked={debug.showCanvasOverlay}
                    onChange={() => dispatch(debugSlice.actions.showCanvasOverlay(!debug.showCanvasOverlay))}
                />
            </ListItem>
            <ListItem key="logo average color">
                <ListItemText primary="Logo: High Contrast Average" />
                <Toggle
                    checked={debug.useAvgColor}
                    onChange={() => dispatch(debugSlice.actions.useAvgColor(!debug.useAvgColor))}
                />
            </ListItem>
            <ListItem key="logo average color">
                <ListItemText
                    primary={`Logo Color: ${Math.round(debug.logoColor.r)}, ${Math.round(
                        debug.logoColor.g
                    )}, ${Math.round(debug.logoColor.b)}, ${Math.round(debug.logoColor.a)}`}
                />
            </ListItem>
        </NestedList>
    ) : null;
};

declare const OneTrust: {
    ToggleInfoDisplay: () => void;
};

const oneTrustButtonCss = css`
    color: rgb(117, 117, 117) !important;
    border: none !important;
    background: inherit !important;
    font-size: inherit !important;
    padding: 0 !important;
`;

export const Sidebar = () => {
    const [oneTrustPromptButton, setOneTrustPromptButton] = useState(<></>);
    useEffect(() => {
        function getOneTrustBanner() {
            // Delete any duplicate markup in the One Trust banner. Modified code from "clearDup()" as seen here
            // https://community.cookiepro.com/s/article/UUID-69162cb7-c4a2-ac70-39a1-ca69c9340046?language=en_US
            const banner = document.getElementsByClassName('otPcCenter');
            if (banner && banner.length > 1) banner[1].remove();
            OneTrust.ToggleInfoDisplay();
        }

        return setOneTrustPromptButton(
            // eslint-disable-next-line react/button-has-type
            <button
                css={oneTrustButtonCss}
                id="ot-sdk-btn"
                className="ot-sdk-show-settings"
                onClick={() => getOneTrustBanner()}
            >
                Do Not Sell My Personal Information
            </button>
        );
    }, []);

    const [isOpen, setIsOpen] = useState(false);
    const toggleIsOpen = () => {
        setIsOpen(!isOpen);
    };
    const {
        showMostVisited,
        toggleShowMostVisited,
        showPopular,
        toggleShowPopular,
        showPhotoGallery,
        toggleShowPhotoGallery
    } = useContext(NewTabContext);

    const muiButtonCss = css`
        height: 30px;
        width: 30px;
        color: black;
        padding: 0;
        border-radius: 50%;
        background-color: #ffffff;
        box-shadow: 0 2px 7px 0 rgba(50, 48, 208, 0.17);

        &:hover {
            background-color: #ffffff;
        }

        svg {
            font-size: 18px;
        }
    `;

    const muiDrawerOpenButtonCss = css`
        margin: 21px;

        ${muiButtonCss};
    `;

    const muiDrawerCloseButtonCss = css`
        padding: 0;
        margin-right: -8px;

        ${muiButtonCss};
    `;

    const muiDrawerHeadingCss = css`
        font-size: 12px;
        font-weight: 600;
        text-align: center;
    `;

    const muiDrawerPanelCss = css`
        width: 333px;
        overflow-x: hidden;
    `;

    const muiListRowCss = css`
        padding: 10px 21px;
    `;

    const muiListCss = css`
        .MuiListItem-gutters {
            ${muiListRowCss};
        }

        .MuiCollapse-wrapper,
        .MuiCollapse-wrapperInner {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
        }

        > li:first-of-type > div > span {
            ${muiDrawerHeadingCss};
        }

        > li:first-of-type > button {
            ${muiDrawerCloseButtonCss};
        }
    `;

    const muiDrawerCss = css`
        width: 0;

        .MuiList-root {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
        }

        > div:nth-of-type(3) {
            ${muiDrawerPanelCss};
        }
    `;

    return (
        <>
            <IconButton
                aria-label="open settings"
                onClick={toggleIsOpen}
                css={css`
                    z-index: 1000;
                    transition: opacity 0.3s ease;
                    opacity: 0.5;
                    && {
                        ${muiDrawerOpenButtonCss}
                    }

                    &:hover {
                        opacity: 1;
                    }
                `}
            >
                <TuneRoundedIcon />
            </IconButton>
            <Drawer
                open={isOpen}
                anchor="right"
                css={muiDrawerCss}
                variant="temporary"
                BackdropProps={{invisible: true}}
                onBackdropClick={toggleIsOpen}
            >
                <List css={muiListCss}>
                    <ListItem>
                        <ListItemText primary="SETTINGS" />
                        <IconButton aria-label="close settings" onClick={toggleIsOpen}>
                            <CloseRoundedIcon />
                        </IconButton>
                    </ListItem>
                    <NestedList
                        heading="SHORTCUTS"
                        containerStyles={css`
                            flex-grow: 0;
                        `}
                    >
                        <ListItem>
                            <ListItemText primary="Show Most Visited" />
                            <Toggle checked={showMostVisited} onChange={toggleShowMostVisited} />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Show Popular" />
                            <Toggle checked={showPopular} onChange={toggleShowPopular} />
                        </ListItem>
                    </NestedList>
                    <NestedList
                        heading="BACKGROUND"
                        containerStyles={css`
                            display: flex;
                            flex-grow: 1;
                        `}
                    >
                        <ListItem>
                            <ListItemText primary="Photo Gallery" />
                            <Toggle checked={showPhotoGallery} onChange={toggleShowPhotoGallery} />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Solid Colors" />
                        </ListItem>
                        <ListItem>
                            <ColorSelect />
                        </ListItem>
                    </NestedList>
                    <DebugMenu />
                    <ListItem
                        css={css`
                            display: flex;
                            flex-grow: 1;

                            a {
                                color: #757575;
                                text-decoration: none;
                            }

                            li {
                                margin-top: auto;
                            }
                        `}
                    >
                        <ListItem>
                            <a href="https://hello.mapquest.com/terms-of-use" target="_blank" rel="noreferrer">
                                Terms of Use
                            </a>
                        </ListItem>
                        <ListItem>
                            <a href="https://hello.mapquest.com/privacy" target="_blank" rel="noreferrer">
                                Privacy Policy
                            </a>
                        </ListItem>
                        <ListItem>{oneTrustPromptButton}</ListItem>
                    </ListItem>
                </List>
            </Drawer>
        </>
    );
};

export default Sidebar;
